<template>
  <em>
    If the audio doesn’t play automatically, please click on the
    <el-button size="mini" @click="() => $emit('play')">Replay</el-button>
    button. If it still doesn’t work, please
    <a target="_blank" href="mailto:support@testadmit.com">contact us</a>. Thanks ♪(･ω･)ﾉ
  </em>
</template>

<script>
export default {};
</script>
